<template>
  <div>
    <b-modal
      id="new-pre-consultation-form-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
    >
      <div class="modal-header">
        <div class="modal-title">
          {{
            form.id
            ? 'Editar pré-consulta personalizada'
            : 'Adicionar pré-consulta personalizada'
          }}
        </div>
        <span class="icon-box">
          <v-close class="icon stroke" @click="closeModal()" />
        </span>
      </div>
      <div class="around-content">
        <validation-observer ref="ProtocolForm">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="nome_consulta">Nome</label>
                <input
                  id="nome_consulta"
                  autocomplete="off"
                  type="text"
                  placeholder="Nome"
                  class="form-control"
                  v-model="form.name"
                  maxlength="20"
                  :readonly="!form.can_edit_name"
                />
                <div
                  v-if="validated && !form.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
                <div
                  v-if="String(form.name).length === 20"
                  class="custom-invalid-feedback"
                >
                  Por favor, insira até 20 caracteres
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="descricao">Descrição</label>
                <div>
                  <b-form-textarea
                    id="descricao"
                    v-model="form.description"
                    placeholder="Descrever"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <div
                    v-if="validated && !form.description"
                    class="custom-invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />

          <div class="wrapper-button">
            <b-button 
              v-if="form.id"
              variant="outline-primary" 
              size="lg" 
              @click="editForm()"
            >
              Salvar alterações
            </b-button>
            <b-button 
              variant="primary" 
              size="lg" 
              @click="form.id ? goToCustomForm() : createCustomForm()"
            >
              {{
                form.id
                ? 'Editar pré-consulta personalizada'
                : 'Criar pré-consulta personalizada'
              }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Close from '@/assets/icons/close.svg'

export default {
  name: 'NewPreConsultationFormModal',
  props: {
    person: Object,
    clinic: Object,
    customForm: Object,
  },
  components: {
    'v-close': Close
  },
  data() {
    return {
      form: {
        name: 'Pré-consulta',
        description: null,
        type: 'PRE_CONSULTATION'
      },
      validated: null
    }
  },
  methods: {
    getDefaultForm () {
      return {
        name: 'Pré-consulta',
        description: null,
        type: 'PRE_CONSULTATION'
      }
    },
    closeModal() {
      this.$bvModal.hide('new-pre-consultation-form-modal')
    },
    clearForm() {
      this.form = {
        name: null,
        description: null
      }
      this.validated = null
    },
    isValidForm() {
      this.validated = true
      return this.form.name && this.form.description
    },
    goToCustomForm () {
      this.$router.push(`configuracoes-da-consulta/${this.form.id}`)
    },
    async editForm () {
      if (!this.isValidForm()) return;

      const isLoading = this.$loading.show()
      try {
        await this.api.updateForm(this.form.id, {
          name: this.form.name,
          description: this.form.description,
        })

        this.$toast.success('Alterações salvas com sucesso!')
        this.$emit('onEdit')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async createCustomForm() {
      if (!this.isValidForm()) return;

      const isLoading = this.$loading.show()
      try {
        const response = await this.api.createCustomForm({
          name: this.form.name,
          description: this.form.description,
          active: true,
          clinic_id: this.clinic.id,
          professional_id: null,
          type: 'PRE_CONSULTATION',
        })

        this.$toast.success('Pré-consulta personalizada criada com sucesso!')
        this.$router.push(`configuracoes-da-consulta/${response.data.id}`)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },

  watch: {
    customForm: function (value) {
      if (value) {
        this.form = value
      } else {
        this.form = this.getDefaultForm()
      }
    }
  }
}
</script>

<style lang="scss">
#new-pre-consultation-form-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    button:nth-child(1) {
      margin-right: 10px;
      color: var(--blue-500);
    }
  }
}
</style>
